import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function usefoodList() {

    const toast = useToast();


    const pageLength = ref(10);

    const columns = [
        {
            label: "Action",
            field: "action",
        },
        {
            label: "Image",
            field: "image",
        },
        {
            label: "Code",
            field: "code",
        },


        {
            label: "Name",
            field: "name",
        },
        {
            label: "Short Description",
            field: "short_text",
        },
        {
            label: "Description",
            field: "long_text",
        },
        {
            label: "Category",
            field: "category_name",
        },

        {
            label: "Sidedish",
            field: "sidedish",
        },
        {
            label: "Ingredients",
            field: "ingredient",
        },

        {
            label: "Courier Price",
            field: "del_price",
        },
        {
            label: "Takeaway Price",
            field: "takeaway_price",
        },
        {
            label: "Reservation Price",
            field: "reserve_price",
        },

        {
            label: "Banquet Price",
            field: "banquet_price",
        },



        {
            label: "specify",
            field: "specify",
        },
        {
            label: "Status",
            field: "status",
        },
        
    ];

    var searchTerm = ref('');


    const food = ref([]);

    const statusVariant = computed(() => {
        const statusColor = {
            1: "light-success",
            0: "light-danger",
        };
        return (status) => statusColor[status];
    })

    const fetchfood = async () => {
        store
            .dispatch('master-food/fetchfood', {})
            .then(response => {

                if (response.data.result == 1) {

                    food.value = response.data.data;

                } else {

                    toast({
                        component: ToastificationContent,
                        props: {
                            title: `${response.data.msg}`,
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                        },
                    })


                }

            })
            .catch((error) => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error: Fetching food',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
            })
    }



    const refetchfood = () => {
        fetchfood();
    }


    const addfood = async (formData) => {
        return store
            .dispatch('master-food/addfood', formData)
            .then(response => {

                if (response.data.result == 1) {

                    toast({
                        component: ToastificationContent,
                        props: {
                            title: `${response.data.msg}`,
                            icon: 'CheckIcon',
                            variant: 'success',
                        },
                    })
                    return true;

                } else {

                    toast({
                        component: ToastificationContent,
                        props: {
                            title: `${response.data.msg}`,
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                        },
                    })


                }



            })
            .catch((error) => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error: Adding food',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
                return false;
            })
    }


    const updatefood = async (formData) => {
        return store
            .dispatch('master-food/updatefood', formData)
            .then(response => {

                if (response.data.result == 1) {

                    toast({
                        component: ToastificationContent,
                        props: {
                            title: `${response.data.msg}`,
                            icon: 'CheckIcon',
                            variant: 'success',
                        },
                    })
                    return true;

                } else {

                    toast({
                        component: ToastificationContent,
                        props: {
                            title: `${response.data.msg}`,
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                        },
                    })


                }



            })
            .catch((error) => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error: Adding food',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
                return false;
            })
    }

    const deletefood = async (formData) => {
        return store
            .dispatch('master-food/deletefood', formData)
            .then(response => {

                if (response.data.result == 1) {

                    toast({
                        component: ToastificationContent,
                        props: {
                            title: `${response.data.msg}`,
                            icon: 'CheckIcon',
                            variant: 'success',
                        },
                    })
                    return true;

                } else {

                    toast({
                        component: ToastificationContent,
                        props: {
                            title: `${response.data.msg}`,
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                        },
                    })


                }



            })
            .catch((error) => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error: Adding food',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
                return false;
            })
    }

    const addUser = async (formData) => {
        return store
            .dispatch('app-user/addUser', formData)
            .then(response => {
                console.log(response);
                if (response.data.success && response.data.success == true) {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: 'User added successfuly',
                            icon: 'CheckIcon',
                            variant: 'Success',
                        },
                    })
                    return true;
                } else {
                    return { error: response.data.status };
                }
            })
            .catch((error) => {
                console.log(error);
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error while adding user',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                });
                return false;
            })
    }



    return {
        fetchfood,
        addfood,
        refetchfood,
        updatefood,
        deletefood,
        columns,
        pageLength,
        searchTerm,
        food,
        statusVariant,


    }
}